type FetchOptions = RequestInit & { timeout?: number };

export const fetchWithTimeout = async (url: string, options: FetchOptions = {}): Promise<Response> => {
    const { timeout = 30000, ...fetchOptions } = options;
    const controller = new AbortController();
    const { signal } = controller;

    const fetchPromise = fetch(url, { ...fetchOptions, signal });

    const timeoutPromise = new Promise<never>((_, reject) => {
        setTimeout(() => {
            controller.abort();
            reject(new Error('请求超时'));
        }, timeout);
    });

    try {
        const response = await Promise.race([fetchPromise, timeoutPromise]);

        if (!response.ok) {
            throw new Error(`请求失败，状态码：${response.status}`);
        }

        return response;
    } catch (error) {
        // 可以在这里处理其他错误，例如网络错误
        throw error;
    }
};
