import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ALIPY_URL, API_HOST } from "../utils/const";
import { useState, useRef, useCallback } from "react";
import { Form, Input, Select, Button, message, Spin, Space } from "antd";
import Captcha from "react-captcha-code";
import CryptoJS from "crypto-js";
// import "./Landing.css";

const LandingPage: React.FC = () => {
  // 检测Ip，是否要跳转到表单页

  const [redirectToLead, setRedirectToLead] = useState(false);

  const checkRedirect = async () => {
    try {
      const postBody = {
        ip: "", // 为空则是用户自己的ip
        category: "lead",
      };
      // 发送请求验证ip
      const response = await fetch(`${API_HOST}open/common/lead/check-ip`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postBody),
      });

      if (!response.ok) {
        throw new Error("网络响应错误");
      }

      const data = await response.json();
      // console.log("服务器返回数据", data);
      if (data.code === 1000) {
        // 跳转到表单页面
        console.log("跳转到表单页");
        const redirectPath = "/lead";
        setRedirectToLead(true);
        document.title = "联系我们";
        return;
      }
    } catch (error) {
      console.error("Error during redirection:", error);
    }
    console.log("默认跳转到支付宝");
    window.location.href = ALIPY_URL;
  };

  useEffect(() => {
    console.log("LandingPage mounted");
    checkRedirect();
  }, []);

  // 表单页相关提交逻辑

  const [form] = Form.useForm();
  const [captcha, setCaptcha] = useState("");
  const [loading, setLoading] = useState(false); // 表单提交中
  const captchaRef = useRef(null);

  const handleChange = useCallback((captcha: string) => {
    // console.log("captcha:", captcha);
    setCaptcha(captcha);
  }, []);

  const handleSubmit = async (values: any) => {
    console.log("submit form:", values);
    setLoading(true);
    try {
      const postBody = values;
      // 对验证码+"touliu"后做md5加密，后端校验这两个值是否一致，仅防君子
      postBody.x = CryptoJS.MD5(captcha + "touliu").toString();
      // console.log("表单数据", postBody);

      // 发送请求
      postBody.city = "";
      postBody.company = "";
      const response = await fetch(`${API_HOST}open/common/lead/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postBody),
      });

      if (!response.ok) {
        throw new Error("网络响应错误");
      }

      const data = await response.json();
      // console.log("服务器返回数据", data);
      if (data.code === 1000) {
        message.success("表单提交成功！");
        // 刷新验证码
        (captchaRef as any).current?.refresh();
      } else {
        message.error(data.message || "表单提交失败");
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      message.error("提交失败，请重试！");
    } finally {
      setLoading(false);
    }
  };

  const whiteLabel = (text: string) => {
    return <span style={{ color: "#ffffff" }}>{text}</span>;
  };

  return (
    <div className={`landing-container w-full flex justify-center h-screen ${!redirectToLead && "items-center"}}`}>
      <Spin spinning={!redirectToLead}>
        {redirectToLead && (
          <Space direction="vertical" className={"w-full max-w-md"} style={{ backgroundColor: "#2266fb" }}>
            <div>
              <img src={"./images/connected.jpg"} alt="联系我们" width={500} height={500} />
            </div>
            <Spin spinning={loading}>
              <div className={"px-8"}>
                <Form form={form} layout="vertical" onFinish={handleSubmit}>
                  <Form.Item name="name" label={whiteLabel("用户姓名")} rules={[{ required: true, message: "请输入您的称呼" }]} labelCol={{ span: 6 }}>
                    <Input placeholder="请输入您的称呼" />
                  </Form.Item>

                  <Form.Item
                    name="phone"
                    label={whiteLabel("手机号")}
                    rules={[
                      { required: true, message: "请输入您的手机号" },
                      { pattern: /^1[0-9]{10}$/, message: "请输入有效的手机号" },
                    ]}
                  >
                    <Input placeholder="请输入您的手机号" />
                  </Form.Item>

                  <Form.Item name="description" label={whiteLabel("需求描述")} rules={[{ required: true, message: "请输入您的需求" }]}>
                    <Input.TextArea rows={4} placeholder="请输入需求描述" />
                  </Form.Item>

                  <Form.Item name="captcha" label={whiteLabel("图片验证码")} rules={[{ required: true, message: "请输入验证码" }]}>
                    <div className="flex justify-between items-center">
                      <Input style={{ width: "calc(100% - 120px)" }} placeholder="请输入验证码" />
                      <Captcha ref={captchaRef} charNum={4} onChange={handleChange} />
                    </div>
                  </Form.Item>

                  <Form.Item>
                    <div className="flex justify-center items-center">
                      <Button type="dashed" htmlType="submit" className="w-40">
                        提交
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </Spin>
          </Space>
        )}
      </Spin>
    </div>
  );
};

export default LandingPage;
