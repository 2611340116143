import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoadingPage from "./pages/LoadingPage";
import LeadPage from "./pages/LeadPage";
import LandingPage from "./pages/Landing";
import ClothesPage from "./pages/ClothesPage";
import ContactPage from "./pages/Contact";
import BrandPage from "./pages/BrandPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<>Hello World!</>} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/loading" element={<LoadingPage />} />
        <Route path="/landing" element={<LandingPage />} />
        <Route path="/lead" element={<LeadPage />} />
        <Route path="/lead-1" element={<LeadPage category="lead" />} />
        <Route path="/lead-2" element={<LeadPage category="lead" />} />
        <Route path="/lead-3" element={<LeadPage category="lead" />} />
        <Route path="/lead-4" element={<LeadPage category="lead" />} />
        <Route path="/lead-5" element={<LeadPage category="lead" />} />
        <Route path="/lead-6" element={<LeadPage category="lead" />} />
        <Route path="/lead-7" element={<LeadPage category="lead" />} />
        <Route path="/lead-8" element={<LeadPage category="lead" />} />
        <Route path="/clothes-1" element={<ClothesPage category="clothes" />} />
        <Route path="/clothes-2" element={<ClothesPage category="clothes" />} />
        <Route path="/clothes-3" element={<ClothesPage category="clothes" />} />
        <Route path="/clothes-4" element={<ClothesPage category="clothes" />} />
        <Route path="/clothes-5" element={<ClothesPage category="clothes" />} />
        <Route path="/clothes-6" element={<ClothesPage category="clothes" />} />
        <Route path="/clothes-7" element={<ClothesPage category="clothes" />} />
        <Route path="/clothes-8" element={<ClothesPage category="clothes" />} />
        <Route path="/brand-1" element={<BrandPage category="brand" />} />
        <Route path="/brand-2" element={<BrandPage category="brand" />} />
        <Route path="/brand-3" element={<BrandPage category="brand" />} />
        <Route path="/brand-4" element={<BrandPage category="brand" />} />
        <Route path="/brand-5" element={<BrandPage category="brand" />} />
        <Route path="/brand-6" element={<BrandPage category="brand" />} />
        <Route path="/brand-7" element={<BrandPage category="brand" />} />
        <Route path="/brand-8" element={<BrandPage category="brand" />} />
      </Routes>
    </Router>
  );
}

export default App;
