import Fingerprint2 from "fingerprintjs2";

export const isWeiXinLogin = () => {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i)) {
        return true; // 微信中打开
    } else {
        return false; // 普通浏览器中打开
    }
}

export function generateUID(length = 32) {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        result += chars[randomIndex];
    }
    return result;
}

export async function getBrowserFingerprint(): Promise<string | null> {
    try {
        const components = await Fingerprint2.getPromise();
        const values = components.map((component) => component.value);
        // components.forEach((component) => {
        //     console.log(component.key, component.value);
        // });
        const murmur = Fingerprint2.x64hash128(values.join(""), 31);
        // console.log("浏览器指纹", murmur);
        return murmur;
    } catch (error) {
        console.error("Error getting fingerprint:", error);
        return null;
    }
}