import { useState, useEffect, useRef, useCallback, lazy, Suspense } from "react";
import { Form, Input, Select, Button, message, Spin, Space } from "antd";
import { getRedirectUrl, submitLeadWithX } from "../api/lead";
import LeadForm from "../component/LeadForm";
// import FingerprintJS from 'fingerprintjs2';
import { generateUID, getBrowserFingerprint } from "../utils/common";

const LeadPage: React.FC = () => {
  // 检测Ip，是否要跳转到表单页
  const FROM = "contact";
  const CATEGORY = "lead";

  const [redirectToZFB, setRedirectToZFB] = useState(false);
  const [showLeadForm, setShowLeadForm] = useState(false);


  // useEffect(() => {
  //   // console.log("Leadpage mounted");
  //   checkRedirect();
  // }, []);

  const LeadForm = lazy(() => import("../component/LeadForm"));

  return (
    // 表单页的格式不同
    <div className={`landing-container w-full flex justify-center h-screen ${redirectToZFB && "items-center"}}`}>
      {/* 默认跳转中... */}
      <Spin spinning={redirectToZFB}>
        {!redirectToZFB &&
          (showLeadForm ? (
            // <LeadForm from={FROM} />
            <Suspense fallback={<div>加载中...</div>}>
              <LeadForm from={FROM} />
            </Suspense>
          ) : (
            <Space direction="vertical" className={"block w-full max-w-md relative"}>
              <div>
                <img src={"./images/lead-homepage.jpg"} alt="首页" />
              </div>
              <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-80 mb-2">
                <Button className="block w-full" type="primary" size="large" onClick={() => setShowLeadForm(true)}>
                  联系我们
                </Button>
              </div>
            </Space>
          ))}
      </Spin>
    </div>
  );
};

export default LeadPage;
