import { API_HOST } from "../utils/const";
import { fetchWithTimeout } from "./common";
import CryptoJS from "crypto-js";

export const getRedirectUrl = async (category: string, from: string, uid?: string, p?: string) => {
    const postBody = {
        ip: "", // 为空则是用户自己的ip
        category,
        from,
        uid,
        p,
    };
    // 发送请求验证ip
    const response = await fetchWithTimeout(`${API_HOST}open/common/lead/check-ip`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(postBody),
        timeout: 10000,
    });

    if (!response.ok) {
        throw new Error("网络响应错误");
    }

    const data = await response.json();
    return data;
}

interface SubmitLeadWithXParams {
    captcha: string;
    name: string;
    phone: string;
    description: string;
    from: string;
    x: string;
    city?: string;
    company?: string;
}
export const submitLeadWithX = async (params: SubmitLeadWithXParams) => {
    // 发送请求
    params.city = "";
    params.company = "";
    const response = await fetch(`${API_HOST}open/common/lead/add`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
    });
    const data = await response.json();
    return data;
}