import { useEffect } from "react";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { ALIPY_URL, API_HOST } from "../utils/const";

const LoadingPage: React.FC = () => {
  const navigate = useNavigate();

  document.title = "";

  const checkRedirect = async () => {
    try {
      const postBody = {
        ip: "", // 为空则是用户自己的ip
        category: "lead",
      };
      // 发送请求验证ip
      const response = await fetch(`${API_HOST}open/common/lead/check-ip`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postBody),
      });

      if (!response.ok) {
        throw new Error("网络响应错误");
      }

      const data = await response.json();
      // console.log("服务器返回数据", data);
      if (data.code === 1000) {
        // 跳转到表单页面
        const redirectPath = "/lead";
        navigate(redirectPath);
      }
    } catch (error) {
      console.error("Error during redirection:", error);
    }
    console.log("默认跳转到支付宝");
    window.location.href = ALIPY_URL;
  };

  useEffect(() => {
    checkRedirect();
  }, []);

  return (
    <div className="flex items-center justify-center h-screen">
      <Spin size="large" />
    </div>
  );
};

export default LoadingPage;
